export default {
  agileDownloadTitle: `La gestion de projet ou les méthodologies agiles vous intéressent?`,
  agileDownloadDescription: `Téléchargez notre guide sur l'importance de la gestion de projet et des méthodes agiles en organisation, ainsi que sur les compétences clés à développer.`,
  leadershipDownloadTitle: `Trouvez le programme adapté à vos besoins.`,
  leadershipDownloadDescription: `Téléchargez notre guide sur nos 3 programmes de leadership pour connaitre celui qui est le mieux adapté à votre réalité. `,
  leadershipDownloadImageAlt: `Couverture d'un magazine sur les programmes de leadership`,
  agileImageAlt: `Couverture d'un magazine sur les avantages des méthodologies agile dans la gestion de projet`,
  backToCatalog: `Retour au catalogue`,
  callAnAdvidsor: `Appelez maintenant au 1 877 624.2344`,
  callUs: `Téléphonez-nous au 1 877 624.2344`,
  choose: `Choisir`,
  chooseCityAndLanguage: `Sélectionnez un lieu`,
  chooseVirtual: `Aucune de ces dates ne vous convient? Sélectionnez Classe virtuelle`,
  citiesAndFormats: `Lieu`,
  closeList: `Fermer la liste`,
  confirmed: `Session garantie`,
  courseOutline: `Plan de cours`,
  downloadTrainingPlan: `Téléchargez le plan de cours`,
  downloadingPrepare: `Téléchargement en préparation`,
  downloadingTrainingPlan: `Téléchargement du plan de cours`,
  downloadingConverting: `Conversion en PDF`,
  date: `Date`,
  date_plural: `Dates`,
  day: `jour`,
  day_plural: `jours`,
  dayWithCount: `{{count, localizedNumber}} jour`,
  dayWithCount_plural: `{{count, localizedNumber}} jours`,
  details: `Détails`,
  duration: `Durée`,
  durationAverage: `Durée moyenne`,
  faqSchedulesPolicies: `FAQ – Horaires et politiques`,
  fundingPrograms: `Programmes de subvention`,
  inClassroom: `En classe`,
  inLang: `en`,
  inLoc: `à`,
  inEnglish: `En anglais`,
  inFrench: `En français`,
  language: `Langue`,
  location: `Diffusion`,
  materialIncluded: `Matériel pédagogique inclus`,
  new: 'Nouveauté',
  noCoursePlan: `
    <p>Ce plan de cours n’est pas disponible en ligne pour l’instant.</p>
    <p>Nous vous invitons à contacter notre service à la clientèle&nbsp;:</p>
    <ul>
      <li><a href="mailto:info@afiexpertise.com" targte="_blank">info@afiexpertise.com</a></li>
      <li>1 877 624.2344</li>
    </ul>
    <p>Au plaisir de vous servir&nbsp;!</p>
  `,
  noScheduledSessionPresentation: `Aucune session au calendrier pour l’instant. \nCe cours est disponible en session privée.`,
  noSessionSuitsNotify: `<strong>Aucune date ne convient?</strong>`,
  noSessionForLocationAndLanguage: `Aucune session disponible dans cette langue d’enseignement à {{city}}. Veuillez sélectionner un nouveau lieu.`,
  notifyWhenSessionAdded: `Notifiez-moi si une session est ajoutée.`,
  onRequest: `Sur demande`,
  or: `ou`,
  preferentialPrice: `Prix préférentiel`,
  preferentialPriceNote: `Nous accordons le prix préférentiel aux organismes publiques, parapubliques, à but non lucratif ou ayant une entente avec AFI.`,
  preferentialPriceTooltip: `Ai-je droit au prix préférentiel?`,
  privateSession: `Session privée`,
  privateSession_plural: `Sessions privées`,
  privateSessionAvailable: `Disponible aussi en <strong>formation privée</strong> ou personnalisée.`,
  privateSessionPresentation: `Ce cours est disponible en session privée ou personnalisée. Il peut être offert à l’un de nos centres de formation ou directement à vos bureaux. Appelez l’un de nos conseillers ou faites une demande de soumission en ligne.`,
  promotionalPrice: `Prix promotionnel`,
  recommendedCourses: `Formations recommandées`,
  register: `Débutez l’inscription`,
  registerMobile: `Réservez ma place`,
  regularPrice: `Prix régulier`,
  requestInformation: `Demande d’informations`,
  requestQuotation: `Demandez un devis`,
  requestQuote: `Demandez une soumission`,
  scheduledSession: `Session au calendrier`,
  scheduledSessionNotification: `Demandez l’ouverture d’une nouvelle session`,
  scheduledOrPrivateSession: `Sessions au calendrier ou privées?`,
  select: `Sélectionnez`,
  selectSessionLocation: `Où désirez-vous la suivre?`,
  selectToShowDates: `Sélectionnez pour afficher les dates ainsi que les tarifs`,
  selfPaced: `À votre rythme`,
  selfPacedTitle: `Produit d’auto-formation en ligne`,
  selfPacedPresentation: `Pour apprendre au moment et au rythme qui vous convient.`,
  sessionFull: `complet`,
  sessionOnEvening: `soirée`,
  sessionOnWeekend: `week-end`,
  sessionRequiredError: `Veuillez d’abord cocher votre sélection.`,
  configurationError: `Erreur de configuration. Variable d’environnement manquante (GATSBY_CART_URL).`,
  showAllSessions: `Voir toutes les sessions`,
  showRestOfPlan: `Afficher la suite du plan de cours`,
  teachersSliderTitle: `Savoir s’entourer des meilleurs`,
  teachingLanguages: `Langue d’enseignement`,
  trainingModule: `Capsules de formation`,
  theme: `Thématique`,
  upcomingSession: `Prochaine session`,
  upcomingSession_plural: `Prochaines sessions`,
  virtualClassroom: `Classe virtuelle`,
  courseTemplateSEOTitle: 'Formations',
}
